import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { styled } from "../../gatsby-theme-stitches/config"

const sanityConfig = {
  projectId: "qf8cbyxs",
  dataset: "production",
}

const StyledImage = styled(GatsbyImage, {
  borderRadius: "$m",
  height: "auto",
  objectFit: "contain !important",
  maxWidth: "100%",
  m: "auto",
  display: "inline-block",
})

const StyledImageContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
})

export const PostImage = ({ node }: { node: any }) => {
  if (!node || !node.asset || !node.asset._ref) {
    return null
  }
  const gatsbyImageData = getGatsbyImageData(
    node.asset._ref,
    { maxWidth: 590 },
    sanityConfig
  )

  return (
    <StyledImageContainer>
      <StyledImage image={gatsbyImageData} alt={node.alt} />
    </StyledImageContainer>
  )
}
