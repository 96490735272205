import React, { FC, useEffect, useMemo, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { useConvexPolyhedron } from "@react-three/cannon"
import { D20GLTF, D20Props } from "./D20.types"
import { toConvexProps } from "./D20.utils"
import type { Mesh } from "three"

export const D20: FC<D20Props> = ({ currentRoll, ...props }) => {
  const { nodes, materials } = useGLTF(
    "/sketches/dungeons-and-dragons-dice/d20.glb"
  ) as D20GLTF

  const args = useMemo(
    () => toConvexProps(nodes.Icosphere.geometry),
    [nodes.Icosphere.geometry]
  )
  const [ref, api] = useConvexPolyhedron(
    () => ({
      args,
      mass: 3,
      rotation: [Math.random(), Math.random(), Math.random()],
      position: [0, 20, 0],
    }),
    useRef<Mesh>(null)
  )

  useEffect(() => {
    if (ref.current) {
      api.position.set(0, 10, 0)
      api.rotation.set(Math.random(), Math.random(), Math.random())
    }
  }, [currentRoll])

  return (
    <group {...props} dispose={null} castShadow={true}>
      <mesh
        ref={ref}
        castShadow={true}
        geometry={nodes.Icosphere.geometry}
        material={materials["d20.png"]}
      />
    </group>
  )
}

useGLTF.preload("/sketches/dungeons-and-dragons-dice/d20.glb")
