import React, { FC, FunctionComponent, ReactNode } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import { PostProps } from "./Post.models"
import { readingTime, toPlainText } from "./Post.utils"
import { PostImage } from "../../components/PostImage"
import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
import { DoubleArrowLeftIcon } from "@radix-ui/react-icons"
import { Stack } from "../../components/Stack"
import { Container } from "../../components/Container.styles"
import { Text } from "../../components/Text/Text"
import { Link } from "../../components/Link"
import { styled } from "../../gatsby-theme-stitches/config"
import { PageHeading } from "../../components/PageHeading"
import {
  ProjectsLink,
  PostsSection,
} from "../../components/PostsList/PostsList.styles"
import { PostsContainer } from "../../components/PostsList/PostsList.styles"
import { PreviousPageLink } from "../../components/PreviousPageLink"
import { Sketch } from "../../components/Sketch"

const StyledYoutube = styled(YouTube, {
  overflow: "hidden",
  iframe: {
    borderRadius: "$m",
    width: "100%",
  },
})

const Post: FunctionComponent<PostProps> = ({
  data: { sanityPost: post },
}): JSX.Element => {
  const timeToRead = readingTime(toPlainText(post._rawBody))

  return (
    <Layout>
      <SEO title={`${post.title}`} />
      <Container>
        <PostsContainer>
          <PreviousPageLink to="/blog/">
            <em>Back to Posts</em>
          </PreviousPageLink>
          <PostsSection>
            <Stack space="l">
              <PageHeading title={post.title}>
                <em>{post.publishedAt}</em> - <strong>{timeToRead}</strong>
              </PageHeading>
              <article>
                <Text as="span" variant="body-1" css={{ lineHeight: 2.0 }}>
                  <BlockContent
                    blocks={post._rawBody}
                    serializers={{
                      marks: {
                        link: (props) => {
                          return (
                            <Link to={props.mark.href}>{props.children}</Link>
                          )
                        },
                      },
                      types: {
                        authorReference: ({ node }) => (
                          <span>{node.author.name}</span>
                        ),
                        image: PostImage,
                        sketch: ({ node }) => <Sketch type={node.type} />,
                        youtube: ({ node }) => {
                          const { url } = node

                          const id = getYouTubeId(url)
                          if (id) {
                            return <StyledYoutube videoId={id} />
                          }
                          return null
                        },
                      },
                    }}
                  />
                </Text>
              </article>
            </Stack>
          </PostsSection>
        </PostsContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Post($id: String) {
    sanityPost(id: { eq: $id }) {
      title
      publishedAt(formatString: "MMMM Do, YYYY")
      _rawBody
      _rawMainImage
      _rawSlug
    }
  }
`
export default Post
