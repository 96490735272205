import React from "react"
import { DungeonsAndDragonsDice } from "./DungeonsAndDragonsDice/DungeonsAndDragonsDice"
import { GravityFalls } from "./GravityFalls/GravityFalls"
import { SketchContainer } from "./Sketch.styles"

const SketchInternal = ({ type }: { type: string }) => {
  switch (type) {
    case "gravityfalls": {
      return <GravityFalls />
    }
    case "dungeons-and-dragons-dice": {
      return <DungeonsAndDragonsDice />
    }
    default: {
      return null
    }
  }
}

export const Sketch = ({ type }: { type: string }) => {
  return (
    <SketchContainer>
      <SketchInternal type={type} />
    </SketchContainer>
  )
}
