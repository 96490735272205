import { ConvexPolyhedronProps } from "@react-three/cannon"
import { BufferGeometry } from "three"
import { Geometry } from "three-stdlib/deprecated/Geometry"

export function toConvexProps(
  bufferGeometry: BufferGeometry
): ConvexPolyhedronProps["args"] {
  const geo = new Geometry().fromBufferGeometry(bufferGeometry)
  geo.mergeVertices()
  return [
    geo.vertices.map((v) => [v.x, v.y, v.z]),
    geo.faces.map((f) => [f.a, f.b, f.c]),
    [],
  ]
}
