import React, { useContext, useState } from "react"
import { Canvas } from "@react-three/fiber"
import { Physics } from "@react-three/cannon"
import { ThemeContext } from "../../ThemeProvider"
import { Button } from "../../Button.styles"
import { Plane } from "./Plane"
import { D20 } from "./D20"

export const DungeonsAndDragonsDice = () => {
  const value = useContext(ThemeContext)
  const [totalRolls, setTotalRolls] = useState(0)

  return (
    <div style={{ height: 500, position: "relative" }}>
      <Button
        onClick={() => setTotalRolls(totalRolls + 1)}
        variant="normal"
        css={{ position: "absolute", zIndex: 20, top: "$m", left: "$m" }}
      >
        Roll again!
      </Button>
      <Canvas
        shadows
        camera={{
          fov: 65,
          near: 0.1,
          far: 1000,
          position: [0, 7, 2],
          rotation: [5, 0, 0],
        }}
      >
        <ThemeContext.Provider value={value}>
          <hemisphereLight intensity={0.35} />
          <directionalLight
            position={[0, 7, 2]}
            intensity={1.5}
            castShadow={true}
            shadow-camera-near={0.1}
            shadow-camera-far={20}
            shadow-camera-left={-10}
            shadow-camera-right={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
          />
          <Physics>
            <Plane />
            <D20 currentRoll={totalRolls} />
          </Physics>
        </ThemeContext.Provider>
      </Canvas>
    </div>
  )
}
