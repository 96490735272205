import React from "react"
import { globalCss, styled } from "../../../gatsby-theme-stitches/config"
import { Text } from "../../../components/Text/Text"

const globalStyles = globalCss({
  "@font-face": {
    fontFamily: "GravitationFalls",
    src: 'url("/sketches/gravityfalls/gravitation_falls.otf")',
  },
})

const GravityFallsContainer = styled("div", {
  position: "absolute",
  "--outside-stroke": "4px",
  transform: `perspective(1000px) rotateX(27deg) rotateY(16deg) rotateZ(347deg) translateX(-50%)`,
  top: 0,
  left: "50%",
  width: "100%",
  h1: {
    letterSpacing: -1,
    fontFamily: "GravitationFalls",
    "-webkit-text-stroke-width": "2px",
    "-webkit-text-stroke-color": "black",
    color: "transparent",
    lineHeight: "1.08",
    zIndex: 10,
    position: "relative",
    textAlign: "center",
    fontSize: 50,
    "@tablet": {
      fontSize: 70,
    },
  },
  "#logo": {
    backgroundImage: "url('/sketches/gravityfalls/background.png')",
    "-webkit-background-clip": "text",
    backgroundPosition: "center",
    backgroundSize: "110%",
    filter: "sepia(100%)",
    transition: "2s ease background",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    "&:hover": {
      backgroundSize: "140%",
    },
  },
  "#shadow": {
    color: "transparent",
    position: "absolute",
    zIndex: 0,
    "-webkit-text-stroke-width": "0px",
    textShadow: "black -6px 4px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "#solid": {
    position: "absolute",
    zIndex: 1,
    color: "#F9681D",
    "-webkit-text-stroke-width": 2,
    top: "calc(50% + 6px)",
    left: "50%",
    transform: "translateX(-50%)",
  },
})

const GravityFallsPostcard = styled("div", {
  backgroundImage: "url('/sketches/gravityfalls/postcard.png')",
  backgroundSize: "cover",
  height: "20vw",
  minHeight: "240px",
  position: "relative",
  "@tablet": {
    minHeight: "340px",
  },
  maxHeight: "588px",
})

export const GravityFalls = () => {
  globalStyles()

  return (
    <GravityFallsPostcard>
      <GravityFallsContainer>
        <Text as="h1" id="solid">
          Gravity
          <br />
          Falls
        </Text>
        <Text as="h1" id="shadow">
          Gravity
          <br />
          Falls
        </Text>
        <Text as="h1" id="logo">
          Gravity
          <br />
          Falls
        </Text>
      </GravityFallsContainer>
    </GravityFallsPostcard>
  )
}
